import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, ColorPicker, Input, ReSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { customIcons } from 'config';
import { useCrud, useTranslations } from 'hooks';
import { runAction } from 'modules/utils';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';
import StaticField from '../components/StaticField';

const TaskGroupForm = forwardRef((props, ref) => {
	const { getOne } = useCrud(props.service);
	const { translate } = useTranslations();

	const [data, setData] = useState([]);
	const [modules, setModules] = useState([]);
	const [activeModule, setActiveModule] = useState(null);
	const [moduleOptions, setModulesOptions] = useState([]);
	const [loading, setLoading] = useState(true);
	const myForm = useRef(null);
	const disabledOnCreateInputTypes = [15, 19];

	const initiateData = async () => {
		if (props?.data?.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);
			});
		} else {
			setLoading(false);
		}
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	const resetData = () => {
		myForm.current.reset();
	};

	useImperativeHandle(ref, () => ({
		getData,
		clear: resetData,
	}));

	const getModuleOptions = async () => {
		try {
			const res = await runAction('taskModules', 'getOptionsList');
			setModulesOptions(res);
		} catch (error) {
			console.error('Error on getting options for task modules ', error);
		}
	};

	const getModules = async () => {
		try {
			const res = await runAction('taskModules', 'getModules');
			setModules(res);
			setActiveModule(res[0]);
		} catch (error) {
			console.error('Error on getting task modules ', error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			await initiateData();
			await getModuleOptions();
			await getModules();
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (modules.length > 0 && data?.moduleId) {
			setActiveModule(modules.find((m) => m.id === parseInt(data?.moduleId)));
		}
	}, [modules, data]);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<div>Loading...</div>}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>

					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='moduleId' value={data?.moduleId} />
					<FormEntry label='module' required={!data?.id}>
						<ReSelect
							required={!data?.id}
							title={data?.id ? 'youCannotModifyThisField' : ''}
							disabled={data?.id}
							name='moduleId'
							options={moduleOptions}
							onChange={(val) => setActiveModule(modules.find((m) => m.id === parseInt(val)))}
							defaultValue={data?.moduleId || modules[0]?.id}
						/>
					</FormEntry>
					<FormEntry label='icon' required>
						<ReSelect
							name='icon'
							required={true}
							options={customIcons.map((m) => ({
								value: m.path,
								label: translate(m.name),
								icon: m.path,
							}))}
							defaultValue={data?.icon}
						/>
					</FormEntry>
					<FormEntry label='name' required>
						<Input
							type='text'
							name='name'
							minLength={3}
							defaultValue={data?.name || ''}
							required={true}
							placeholder={'name'}
						/>
					</FormEntry>
					<FormEntry label='notifyTaskBefore' required>
						<Input
							type='number'
							name='notifyTaskReadyXMinutesBefore'
							defaultValue={data?.notifyTaskReadyXMinutesBefore}
							min={0}
							placeholder={'notifyTaskReadyXMinutesBefore'}
							sideLabel={'mins'}
						/>
					</FormEntry>
					<FormEntry label='color'>
						<ColorPicker name='color' value={data?.color} />
					</FormEntry>
					<FormEntry label={'defaultOpen'}>
						<CheckBox name='defaultOpen' label='defaultOpen' selected={data?.defaultOpen} />
					</FormEntry>

					<InternalFormDivider>{translate('thisModule', false, activeModule?.name)}</InternalFormDivider>
					<tr>
						<td colSpan={2} className='text-sm'>
							<div
								dangerouslySetInnerHTML={{
									__html: activeModule ? translate(activeModule?.description) : '',
								}}
							></div>
							<div className='py-3'></div>
						</td>
					</tr>

					<InternalFormDivider>
						{activeModule?.name ? translate('thisTaskFields', false, activeModule?.name) : ''}
					</InternalFormDivider>
					{activeModule?.fields?.map((field, index) => {
						return (
							<StaticField
								taskGroupId={props.data.id}
								key={index}
								disabled={!props.data.id && disabledOnCreateInputTypes.includes(field.inputType)}
								formKey={`fields[${index}]`}
								inputType={field.inputType}
								staticFieldId={field.staticFieldId}
								isComplex={field.isComplex}
								options={field.values}
								name={
									data?.fields?.find((f) => f.staticFieldId === field.staticFieldId)?.name ||
									field.name
								}
								defaultData={data?.fields?.find((f) => f.staticFieldId === field.staticFieldId)}
							/>
						);
					})}
				</Form>
			</div>
		</Suspense>
	);
});

export default TaskGroupForm;
