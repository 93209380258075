import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';

const TasksPermissions = () => {
	const { hasPermission } = usePermissions();
	return {
		tasks: {
			tasks: {
				Create: hasPermission(RESOURCE.TaskItem, PERMISSION.Create),
				View: hasPermission(RESOURCE.TaskItem, PERMISSION.View),
				Update: hasPermission(RESOURCE.TaskItem, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.TaskItem, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.TaskItem, PERMISSION.Export),
				Allocate: hasPermission(RESOURCE.TaskItem, PERMISSION.Allocate),
			},
			employees: {
				Create: false,
				View: hasPermission(RESOURCE.AccessDashboardPanel, PERMISSION.OnlineEmployees),
				Update: false,
				Delete: false,
				Export: false,
			},
			teams: {
				Create: false,
				View: hasPermission(RESOURCE.AccessDashboardPanel, PERMISSION.TeamOverview),
				Update: false,
				Delete: false,
				Export: false,
			},
		},
		dashboard: {
			View: hasPermission(RESOURCE.Dashboard, PERMISSION.View),
			Export: hasPermission(RESOURCE.Dashboard, PERMISSION.Export),
		},

		planning: {
			Create: hasPermission(RESOURCE.Schedule, PERMISSION.Create),
			View: hasPermission(RESOURCE.Schedule, PERMISSION.View),
			Update: hasPermission(RESOURCE.Schedule, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.Schedule, PERMISSION.Delete),
		},
		rules: {
			General: {
				// Overview: {
				// 	View: false, // IF YOU NEED OVERVIEW just change this to true
				// },

				PriorityRule: {
					Create: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					View: hasPermission(RESOURCE.Rule, PERMISSION.View),
					Update: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Rule, PERMISSION.Delete),
				},
				AdjustStaRule: {
					Create: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					View: hasPermission(RESOURCE.Rule, PERMISSION.View),
					Update: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Rule, PERMISSION.Delete),
				},
				GroupingTaskRule: {
					Create: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					View: hasPermission(RESOURCE.Rule, PERMISSION.View),
					Update: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Rule, PERMISSION.Delete),
				},
				ChangeTaskTypeRule: {
					Create: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					View: hasPermission(RESOURCE.Rule, PERMISSION.View),
					Update: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Rule, PERMISSION.Delete),
				},
				MonitorDoubleTaskRule: {
					Create: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					View: hasPermission(RESOURCE.Rule, PERMISSION.View),
					Update: hasPermission(RESOURCE.Rule, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Rule, PERMISSION.Delete),
				},
			},
			DefaultSettings: {
				NoReturn: {
					Create: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					View: hasPermission(RESOURCE.DefaultSetting, PERMISSION.View),
					Update: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Delete),
				},
				TwoPorters: {
					Create: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					View: hasPermission(RESOURCE.DefaultSetting, PERMISSION.View),
					Update: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Delete),
				},
				Provisional: {
					Create: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					View: hasPermission(RESOURCE.DefaultSetting, PERMISSION.View),
					Update: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Delete),
				},
				AccompaniedByNurse: {
					Create: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					View: hasPermission(RESOURCE.DefaultSetting, PERMISSION.View),
					Update: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.DefaultSetting, PERMISSION.Delete),
				},
			},
			Automation: {
				TaskAutomationRule: {
					Create: hasPermission(RESOURCE.TaskAutomationRule, PERMISSION.Create),
					View: hasPermission(RESOURCE.TaskAutomationRule, PERMISSION.View),
					Update: hasPermission(RESOURCE.TaskAutomationRule, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.TaskAutomationRule, PERMISSION.Delete),
				},
			},
		},
		manage: {
			taskTemplates: {
				TaskTemplates: {
					Create: hasPermission(RESOURCE.Template, PERMISSION.Create),
					View: hasPermission(RESOURCE.Template, PERMISSION.View),
					Update: hasPermission(RESOURCE.Template, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Template, PERMISSION.Delete),
					Export: hasPermission(RESOURCE.Template, PERMISSION.Export),
				},
				Documentations: {
					Create: hasPermission(RESOURCE.Documentations, PERMISSION.Create),
					View: hasPermission(RESOURCE.Documentations, PERMISSION.View),
					Update: hasPermission(RESOURCE.Documentations, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Documentations, PERMISSION.Delete),
					Export: hasPermission(RESOURCE.Documentations, PERMISSION.Export),
				},
			},
			taskTypes: {
				TaskTypes: {
					Create: hasPermission(RESOURCE.TaskType, PERMISSION.Create),
					View: hasPermission(RESOURCE.TaskType, PERMISSION.View),
					Update: hasPermission(RESOURCE.TaskType, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.TaskType, PERMISSION.Delete),
					Export: hasPermission(RESOURCE.TaskType, PERMISSION.Export),
				},
			},
		},
		settings: {
			General: {
				// Overview: {
				// 	View: true,
				// },
				CustomFields: {
					Create: hasPermission(RESOURCE.CustomField, PERMISSION.Create),
					View: hasPermission(RESOURCE.CustomField, PERMISSION.View),
					Update: hasPermission(RESOURCE.CustomField, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.CustomField, PERMISSION.Delete),
				},
				Urgencies: {
					Create: hasPermission(RESOURCE.Urgency, PERMISSION.Create),
					View: hasPermission(RESOURCE.Urgency, PERMISSION.View),
					Update: hasPermission(RESOURCE.Urgency, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.Urgency, PERMISSION.Delete),
				},
			},

			ComplexActions: {
				CancelAction: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
				BlockAction: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
				CancelAndCopyAction: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
				CancelAndReturnAction: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
				CompleteAndCopyAction: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
				CompleteAndReturnAction: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
				RefuseAction: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
				ChangeTaskProperties: {
					Create: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					View: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.View),
					Update: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Create),
					Delete: hasPermission(RESOURCE.ComplexTaskAction, PERMISSION.Delete),
				},
			},
		},
	};
};
export default TasksPermissions;
