import { FormEntry, InternalFormDivider } from 'components/form';
import { Input, Radio, ReSelect } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { runAction } from 'modules/utils';
import { useEffect, useState } from 'react';
import DayOfMonth from '../../components/DayOfMonthInput';
import DayOfWeeksInput from '../../components/DaysOfWeekInput';

const RECURRENCE_PATTERN_OPTIONS = [
	{ label: 'dayOfMonth', value: 1 },
	{ label: 'dayOfWeek', value: 2 },
];

const MonthlyForm = ({ isView, data }) => {
	const { translate } = useTranslations();

	const [monthlyOccurrenceOptions, setMonthlyOccurrenceOptions] = useState([]);
	const [activeRecurrencePattern, setActiveRecurrencePattern] = useState(1);

	useEffect(() => {
		if (data?.recurrenceDailyModel?.recurrencePattern) {
			setActiveRecurrencePattern(data?.recurrenceDailyModel?.recurrencePattern);
		}
	}, [data]);

	const fetchRecurrenceTypes = async () => {
		await runAction('tenants', 'getEnum', 'WeekOfTheMonthOccurence')
			.then((enumValues) => {
				const enumOptions = [];
				Object.keys(enumValues).forEach((key) => {
					enumOptions.push({
						label: translate(key, true),
						value: enumValues[key],
					});
				});

				setMonthlyOccurrenceOptions(enumOptions);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		fetchRecurrenceTypes();
	}, []);

	return (
		<>
			<InternalFormDivider>{translate('monthlyRecurrence')}</InternalFormDivider>
			<FormEntry label='every'>
				<Radio
					options={RECURRENCE_PATTERN_OPTIONS}
					name='recurrenceMonthlyModel.recurrencePattern'
					alignment='horizontal'
					defaultSelected={data?.recurrenceMonthlyModel?.recurrencePattern || activeRecurrencePattern}
					onChange={(e) => {
						const value = parseInt(e.target.value);
						if (!value) {
							setActiveRecurrencePattern(1);
							return;
						}
						setActiveRecurrencePattern(value);
					}}
					getFirstItemAsDefault={false}
				/>
			</FormEntry>
			{activeRecurrencePattern === 1 && (
				<FormEntry label='date'>
					<DayOfMonth
						required
						defaultSelected={data?.recurrenceMonthlyModel?.dayOfMonth}
						name='recurrenceMonthlyModel.dayOfMonth'
					/>
				</FormEntry>
			)}

			{activeRecurrencePattern === 2 && (
				<>
					<FormEntry label='onEach'>
						<ReSelect
							required
							isView={isView}
							name='recurrenceMonthlyModel.monthlyOccurrence'
							options={monthlyOccurrenceOptions}
							defaultSelected={data?.recurrenceMonthlyModel?.monthlyOccurrence}
						/>
					</FormEntry>
					<FormEntry label='day'>
						<DayOfWeeksInput
							required
							isMultiple={false}
							defaultSelected={data?.recurrenceMonthlyModel?.dayOfWeek}
							name='recurrenceMonthlyModel.dayOfWeek'
						/>
					</FormEntry>
				</>
			)}

			<FormEntry label='ofEvery'>
				<Input
					required
					isView={isView}
					type='number'
					min={0}
					placeholder={'interval'}
					name='recurrenceMonthlyModel.interval'
					defaultValue={data?.recurrenceMonthlyModel?.interval || 1}
					sideLabel={translate('months')}
				/>
			</FormEntry>
		</>
	);
};
export default MonthlyForm;
