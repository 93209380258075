import { FormEntry, InternalFormDivider } from 'components/form';
import { Input, Radio } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { useEffect, useState } from 'react';

const RECURRENCE_PATTERN_OPTIONS = [
	{ label: 'intervalOfDay', value: 1 },
	{ label: 'weekDay', value: 2 },
];
const DailyForm = ({ isView, data }) => {
	const { translate } = useTranslations();
	const [activeRecurrencePattern, setActiveRecurrencePattern] = useState(1);

	useEffect(() => {
		if (data?.recurrenceDailyModel?.recurrencePattern) {
			setActiveRecurrencePattern(data?.recurrenceDailyModel?.recurrencePattern);
		}
	}, [data]);

	return (
		<>
			<InternalFormDivider>{translate('dailyRecurrence')}</InternalFormDivider>
			<FormEntry label='every'>
				<Radio
					options={RECURRENCE_PATTERN_OPTIONS}
					name='recurrenceDailyModel.recurrencePattern'
					alignment='horizontal'
					defaultSelected={data?.recurrenceDailyModel?.recurrencePattern || activeRecurrencePattern}
					onChange={(e) => {
						const value = parseInt(e.target.value);
						if (!value) {
							setActiveRecurrencePattern(1);
							return;
						}
						setActiveRecurrencePattern(value);
					}}
					getFirstItemAsDefault={false}
				/>
			</FormEntry>
			{activeRecurrencePattern === 1 && (
				<FormEntry label='recurEvery'>
					<Input
						required
						isView={isView}
						type='number'
						min={0}
						placeholder={'interval'}
						name='recurrenceDailyModel.interval'
						defaultValue={data?.recurrenceDailyModel?.interval}
						sideLabel={translate('days')}
					/>
				</FormEntry>
			)}

			{activeRecurrencePattern === 2 && (
				<input type='hidden' name='recurrenceDailyModel.everyWeekday' value={true} />
			)}
		</>
	);
};
export default DailyForm;
