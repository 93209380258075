import AbstractService from 'services/Service';
const endpoint = 'schedules';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
		this.queryString = 'page=1&sortBy=occurrenceDate&pageSize=500&sortAsc=true';
	}
}

export default Service;
