import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { withSuspense } from 'hoc';
import { useAside, useToolbar, useTranslations } from 'hooks';

import { SegmentedControl } from 'components/ui/Interactive';
import { useOutletContext } from 'react-router-dom';

import { postImport } from 'modules/addresses/services/addresses';
import { runAction } from 'modules/utils';
import { themeConfig } from 'utils';
import ImportForm from './forms/import.form';
import TableView from './parts/TableView';
import TreeView from './parts/TreeView';

const VIEWS = {
	TREE: 0,
	TABLE: 1,
};

const AddressesPage = () => {
	const { translate } = useTranslations();
	const { crumbsBuilder, actionsBuilder } = useToolbar();

	const { asideBuilder } = useAside();

	const { permissionsMap } = useOutletContext();

	const [selectedSegment, setSelectedSegment] = useState(0);
	const [tenantSettings, setTenantSettings] = useState(null);
	const [rootLevel, setRootLevel] = useState(null);
	const [hasMultiSites, setHasMultiSites] = useState(false);

	const getTenantSettings = async () => {
		try {
			const res = await runAction('tenants', 'getSettings');
			return res ?? null;
		} catch (err) {
			console.error(err);
			throw err;
		}
	};

	const getTenantLicenseSettings = async () => {
		try {
			const res = await runAction('tenants', 'getLicenseSettings');
			return res ?? null;
		} catch (err) {
			console.error(err);
			throw err;
		}
	};

	const handleImport = (file) => {
		console.log('Import should start now');
		Swal.fire({
			title: translate('youAreAboutToImportAddresses'),
			showCancelButton: true,
			confirmButtonText: translate('confirm'),
			icon: 'warning',
			showLoaderOnConfirm: true,
			cancelButtonText: translate('cancel'),
			confirmButtonColor: themeConfig.color.primary,
			cancelButtonColor: '#b5bec9',
			preConfirm: async () => {
				asideBuilder.setOpen(false);
				asideBuilder.build();

				try {
					const res = await postImport(file);
					console.log('Response from file upload', res);
					return {
						success: res?.succeeded,
						message: res?.message === 'ActionFailed' ? null : res.message,
					};
				} catch (e) {
					console.log('Error in import', e);
					return { success: false, message: null };
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result) => {
			if (result.value?.success) {
				Swal.fire({
					icon: 'success',
					title: translate('successfullyImported'),
					confirmButtonColor: themeConfig.color.primary,
					cancelButtonColor: '#b5bec9',
				}).then(() => window.location.reload());
			} else {
				Swal.fire({
					icon: 'error',
					title: translate('thereWasAProblemWihImportingThisFile'),
					html: `
							<div>
								<p>${result.value?.message || translate('pleaseCheckTheFileAndUseTheProvidedImportTemplate')}<p>
							</div>
						`,
					confirmButtonColor: themeConfig.color.primary,
				});
			}
		});
	};

	const openImport = () => {
		asideBuilder.setTitle('New Import');
		asideBuilder.setComponent(ImportForm);
		asideBuilder.setComponentKey(`new-import-${new Date()}`);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentProps({
			onImportStart: handleImport,
		});
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	useEffect(() => {
		crumbsBuilder.addCurrent('locations');
		crumbsBuilder.build();

		if (permissionsMap.Import) {
			const importAction = actionsBuilder.newAction('Import', 'ri-contacts-book-upload-line');
			importAction.callback = openImport;
			actionsBuilder.addAction(importAction);
			actionsBuilder.build();
		}

		return () => {
			actionsBuilder.reset();
		};
	}, [permissionsMap]);

	useEffect(() => {
		getTenantSettings().then((res) => {
			setTenantSettings(res);
		});

		getTenantLicenseSettings()
			.then((res) => {
				if (res) {
					setRootLevel(decideRootLevel(res.hasGroupsLocation, res.hasCampusesLocations));
					setHasMultiSites(res.hasMultiSites);
				}
			})
			.catch((err) => {
				setRootLevel(6);
			});
	}, []);

	const decideRootLevel = (hasGroupsLocation = true, hasCampusesLocations) => {
		if (!hasGroupsLocation) {
			if (!hasCampusesLocations) {
				return 4;
			}
			return 3;
		}
		return 6;
	};

	return (
		permissionsMap.View && (
			<>
				<div className='w-full h-full flex flex-col'>
					<div className='flex flex-row mb-4 justify-between'>
						<div className='flex'></div>
						<SegmentedControl
							withIcons={true}
							segments={['ri-node-tree', 'ri-table-line']}
							onSegmentChange={(index) => setSelectedSegment(index)}
						/>
					</div>

					<div className='grid grid-cols-1 gap-2 w-full'>
						{selectedSegment === VIEWS.TREE ? (
							<TreeView
								permissions={permissionsMap}
								rootLevel={rootLevel}
								allowMultipleSite={hasMultiSites}
							/>
						) : (
							<TableView permissions={permissionsMap} rootLevel={rootLevel} />
						)}
					</div>
				</div>
			</>
		)
	);
};

export default withSuspense(AddressesPage);
