import { useTranslations } from 'hooks';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import FormBuilder from 'components/form/FormBuilder';

import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { Input } from 'components/ui/Input';
import { getRoomTypes } from 'modules/addresses/services/addresses';
import { addressFormFields } from '../../../models/address.model';

const ALLOWED_FIELDS = [
	'externalId',
	'notificationEmails',
	'emailSubjectOnTaskReady',
	'emailSubjectOnTaskAssigned',
	'emailSubjectOnTaskCompleted',
	'emailSubjectOnTaskCancelled',
	'taskReadyFromLocationNotification',
	'taskReadyToLocationNotification',
	'taskAssignedFromLocationNotification',
	'taskAssignedToLocationNotification',
	'taskCancelledFromLocationNotification',
	'taskCancelledToLocationNotification',
	'taskCompletedFromLocationNotification',
	'taskCompletedToLocationNotification',
];

const AddressForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const formRef = useRef(null);

	const [values, setValues] = useState({});
	const [siblings, setSiblings] = useState(null);
	const [roomTypes, setRoomTypes] = useState([]);

	useImperativeHandle(ref, () => ({
		getData: () => null,
		clear: () => formRef.current?.clear(),
		runSubmit: () => formRef.current?.submit(),
	}));

	// -----------------------------------
	// Effects
	// -----------------------------------
	const loadData = useCallback(async () => {
		// -----------------------------------
		// Edit Data
		// -----------------------------------
		if (props.isEdit) {
			const res = await props.load(props.id);
			if (!res?.data) return;
			const data = res.data;

			const vals = {
				name: data?.name,
			};

			let siblings = null;

			if (props.level === 3 || props.level === 4) {
				siblings = [];
				data.distances?.forEach((dist) => {
					vals[`sibling:${dist.siblingToId}`] = dist.distance;
					siblings.push({
						id: dist.siblingToId,
						name: dist.siblingToName,
					});
				});
				setSiblings(siblings);
			}

			if (props.level === 2) {
				vals['RoomTypeId'] = parseInt(data.roomType.id, 10);
			}

			ALLOWED_FIELDS.forEach((field) => {
				if (field in data) {
					vals[field] = data[field];
				}
			});

			setValues(vals);
		}

		// -----------------------------------
		// General Data
		// -----------------------------------
		if (props.level === 2) {
			const rt = await getRoomTypes();
			setRoomTypes(rt.data.map((rtt) => ({ value: rtt.id, label: rtt.name })));
		}
	}, [props]);

	useEffect(() => {
		loadData();
	}, []);

	return (
		<>
			<div className='w-full h-100 pb-40 overflow-y-auto'>
				{props.isEdit && (
					<>
						<Form>
							<InternalFormDivider className=''>{translate('details')}</InternalFormDivider>
							<FormEntry label='ID'>
								<Input
									isView={isView}
									type='text'
									name='id-placeholder'
									value={props.id}
									disabled
									className='text-right'
								/>
							</FormEntry>
						</Form>
					</>
				)}
				<FormBuilder
					isView={isView}
					ref={formRef}
					schema={addressFormFields(translate, props.type, props.level, props.siblings || siblings || [])}
					extraProps={{ roomTypes }}
					values={values || {}}
					onSubmit={(data) => props.submitCallback(data)}
				/>
			</div>
		</>
	);
});

export default AddressForm;
