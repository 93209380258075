import { useAside, useTranslations } from 'hooks';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
// import { Button } from "components/ui/Input";
import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
import { useNavigate } from 'react-router-dom';
import TaskService from '../../services';
import ActionButton from './ActionButton';
import AllocateHandler from './AllocateHandler';
import MultipleCopyForm from './MultipleCopy';
import ReturnTask from './ReturnTask';

const ActionRow = ({
	item,
	taskActions,
	fetchAndLoad,
	editTask = () => {},
	viewTask = () => {},
	cloneTask = () => {},
	modalsVisible = () => {},
}) => {
	const { translate } = useTranslations();
	const { asideBuilder } = useAside();
	const { hasPermission } = usePermissions();
	const navigate = useNavigate();

	const hasViewPermission = hasPermission(RESOURCE.TaskItem, PERMISSION.View);

	const service = new TaskService();

	const status = item?.status?.id;
	const noReturn = item?.noReturn || false;

	const [returnDialogVisible, setReturnDialogVisible] = useState(false);
	const [allocateDialogVisible, setAllocateDialogVisible] = useState(false);

	const buttons = [
		// {
		// 	id: 1,
		// 	label: "assign",
		// 	icon: "ri-user-add-line",
		// 	color: "primary",
		// 	onClick: (item) => {
		// 		console.log("assign", item);
		// 	},
		// },
		{
			id: -1,
			label: 'view',
			icon: 'ri-eye-line',
			color: 'primary',
			onClick: (item) => {
				viewTask(item);
			},
		},
		{
			id: 13,
			label: 'adjust',
			icon: 'ri-pencil-line',
			color: 'primary',
			onClick: (item) => {
				editTask(item);
			},
		},
		{
			id: null,
			label: 'copy',
			icon: 'ri-file-copy-line',
			color: 'secondary',
			isGroup: true,
			children: [
				{
					id: 10,
					label: 'singleCopy',
					icon: 'ri-file-copy-line',
					color: 'secondary',
					onClick: function (item) {
						TaskActionHandler(
							item,
							this.id,
							{
								title: 'singleCopyTask',
								text: 'doYouWantToCreateASimilarCopyOfThisTask',
								icon: 'ri-file-copy-line',
								confirmButtonText: 'copy',
								confirmButtonColor: 'rgb(107, 114, 128)',
								cancelButtonText: 'cancel',
							},
							'performAction',
						);
					},
				},
				{
					id: 11,
					label: 'multipleCopy',
					icon: 'ri-file-copy-2-line',
					color: 'secondary',
					onClick: function (item) {
						MultipleCopyHandler(item, this.id);
					},
				},
				{
					id: 12,
					label: 'singleCopyAndAdjust',
					icon: 'ri-edit-box-line',
					color: 'secondary',
					onClick: function (item) {
						TaskActionHandler(
							item,
							this.id,
							{
								title: 'singleCopyAndAdjustTask',
								text: 'doYouWantToCreateASimilarCopyOfThisTaskAndToAdjustIt',
								icon: 'ri-edit-box-line',
								confirmButtonText: 'copy',
								confirmButtonColor: 'rgb(107, 114, 128)',
								cancelButtonText: 'cancel',
							},
							'performAction',
							(data) => {
								editTask({ ...item, id: data });
							},
						);
					},
				},
			],
		},

		{
			id: 2,
			label: 'accept',
			icon: 'ri-check-line',
			color: 'success',
			onClick: function (item) {
				TaskActionHandler(item, this.id, {
					title: 'acceptTask',
					text: 'doYouWantToAcceptThisTask',
					icon: 'ri-check-line',
					confirmButtonText: 'accept',
					confirmButtonColor: 'rgb(34, 197, 94)',
					cancelButtonText: 'cancel',
				});
			},
		},
		{
			id: 3,
			label: 'start',
			icon: 'ri-flag-line',
			color: 'primary',
			onClick: function (item) {
				TaskActionHandler(item, this.id, {
					title: 'startTask',
					text: 'doYouWantToStartThisTask',
					icon: 'ri-flag-line',
					confirmButtonText: 'start',
					confirmButtonColor: 'rgb(59, 130, 246)',
					cancelButtonText: 'cancel',
				});
			},
		},
		{
			id: 4,
			label: 'complete',
			icon: 'ri-check-double-line',
			color: 'success',
			onClick: function (item) {
				TaskActionHandler(item, this.id, {
					title: 'completeTask',
					text: 'doYouWantToCompleteThisTask',
					icon: 'ri-check-double-line',
					confirmButtonText: 'complete',
					confirmButtonColor: 'rgb(34, 197, 94)',
					cancelButtonText: 'cancel',
				});
			},
		},
		{
			id: 5,
			label: 'cancel',
			icon: 'ri-close-line',
			color: 'danger',
			onClick: function (item) {
				TaskActionHandler(item, this.id, {
					title: 'cancelTask',
					text: 'doYouWantToCancelThisTask',
					icon: 'ri-close-line',
					confirmButtonText: 'cancel',
					confirmButtonColor: 'rgb(239, 68, 68)',
					cancelButtonText: 'cancel',
				});
			},
		},
		!noReturn && {
			id: 14,
			label: 'noReturn',
			icon: 'ri-arrow-right-double-line',
			color: 'danger',
			onClick: function (item) {
				TaskActionHandler(item, this.id, {
					title: 'noReturn',
					text: 'doYouWantToMarkThisTaskAsNoReturn',
					icon: 'ri-arrow-right-double-line',
					confirmButtonText: 'noReturn',
					confirmButtonColor: 'rgb(239, 68, 68)',
					cancelButtonText: 'cancel',
				});
			},
		},
		!noReturn && {
			id: 15,
			label: 'return',
			icon: 'ri-arrow-go-back-line',
			color: 'primary',
			onClick: function (item) {
				setReturnDialogVisible(true);
			},
		},

		{
			id: 8,
			label: 'allocate',
			icon: 'ri-map-pin-range-line',
			color: 'warning',
			onClick: (item) => {
				setAllocateDialogVisible(true);
			},
		},
		{
			id: 9,
			label: 'block',
			icon: 'ri-forbid-line',
			color: 'danger',
			onClick: function (item) {
				BlockActionHandler(item, this.id);
			},
		},

		{
			id: 7,
			label: 'Activate',
			icon: 'ri-refresh-line',
			color: 'success',
			onClick: function (item) {
				TaskActionHandler(item, this.id, {
					title: 'activateTask',
					text: 'doYouWantToActivateThisTask',
					icon: 'ri-refresh-line',
					confirmButtonText: 'Activate',
					confirmButtonColor: 'rgb(34, 197, 94)',
					cancelButtonText: 'cancel',
				});
			},
		},
		{
			id: 6,
			label: 'Deactivate',
			icon: 'ri-rotate-lock-line',
			color: 'danger',
			onClick: function (item) {
				TaskActionHandler(item, this.id, {
					title: 'deactivateTask',
					text: 'doYouWantToDeactivateThisTask',
					icon: 'ri-rotate-lock-line',
					confirmButtonText: 'Deactivate',
					confirmButtonColor: 'rgb(239, 68, 68)',
					cancelButtonText: 'cancel',
				});
			},
		},
		{
			id: 24,
			label: 'Schedule',
			icon: 'ri-calendar-event-line',
			color: 'success',
			onClick: function (item) {
				navigate('/tasks/planning', { state: { taskItem: item, taskId: this.id } });
			},
		},
	];

	const TaskActionHandler = (
		item,
		id,
		{ title, text, icon, confirmButtonText, confirmButtonColor, cancelButtonText, titleParam = null },
		serviceAction,
		onSuccess = () => {},
	) => {
		let createAnyWay = false;
		Swal.fire({
			title: translate(title),
			text: translate(text, false, titleParam),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: `<i class='${icon}'></i> ${translate(confirmButtonText)}`,
			confirmButtonColor: confirmButtonColor,
			cancelButtonColor: '#b5bec9',
			cancelButtonText: `${translate(cancelButtonText)}`,
			showLoaderOnConfirm: true,
			preConfirm: async () => {
				try {
					if (serviceAction) {
						await service[serviceAction](item.id, id, {
							createAnyWay,
						}).then((response) => {
							if (response.succeeded === false) {
								if (response.message === 'DuplicatedEntity') {
									createAnyWay = true;
									Swal.update({
										confirmButtonText: translate('createAnyWay'),
										confirmButtonColor: 'rgb(239, 68, 68)',
									});
									Swal.hideLoading();
									Swal.showValidationMessage(
										translate(response.message) || translate('somethingWentWrong'),
									);
									return;
								} else {
									Swal.showValidationMessage(
										translate(response.message) || translate('somethingWentWrong'),
									);
									return;
								}
							} else {
								onSuccess(response.data);
								toast(translate(title + 'performedSuccessfully'), {
									duration: 2000,
									icon: '✅',
								});
							}
						});
					} else {
						await service.changeStatus([item.id], id).then((response) => {
							if (response.succeeded === false) {
								Swal.showValidationMessage(
									translate(response.message) || translate('somethingWentWrong'),
								);
								return;
							} else {
								onSuccess(response.data);
								toast(translate(title + 'performedSuccessfully'), {
									duration: 2000,
									icon: '✅',
								});
							}
						});
					}
					fetchAndLoad();
				} catch (error) {
					console.error('Error on action', error);
					Swal.hideLoading();
					Swal.showValidationMessage(translate(error.message) || translate('somethingWentWrong'));
					throw error;
				}
			},
		});
	};

	const BlockActionHandler = (item, id) => {
		Swal.fire({
			title: translate('doYouWantToBlockThisTask'),
			// text: `Do you want to block this task?`,
			input: 'number',
			inputLabel: translate('enterTheNumberOfMinutesToBlockThisTask'),
			inputAttributes: {
				min: 1,
				step: 1,
				required: true,
			},
			inputValidator: (value) => {
				if (!value || value < 1) {
					return translate('youNeedToEnterTheNumberOfMinutes');
				}
			},
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'rgb(239, 68, 68)',
			confirmButtonText: `<i class='ri-forbid-line'></i> ${translate('block')}`,
			cancelButtonText: translate('cancel'),
			cancelButtonColor: '#b5bec9',
			showLoaderOnConfirm: true,
			preConfirm: async () => {
				try {
					// await service.performAction([item.id], id);
					await service.blockTask(item.id, Swal.getInput().value).then((response) => {
						if (response.succeeded === false) {
							Swal.showValidationMessage(translate(response.message) || translate('somethingWentWrong'));
							return;
						} else {
							toast(translate('taskBlockedSuccessfully'), {
								duration: 2000,
								icon: '✅',
							});
							asideBuilder.setOpen(false);
							asideBuilder.reset();
						}
					});

					fetchAndLoad();
				} catch (error) {
					console.error('Error on action', error);
					Swal.hideLoading();
					Swal.showValidationMessage(translate(error.message) || translate('somethingWentWrong'));
					throw error;
				}
			},
		});
	};

	const MultipleCopyHandler = async (item, id) => {
		asideBuilder.setResetOnClose(true);
		asideBuilder.setRight(false);
		asideBuilder.setOpen(true);
		asideBuilder.setTitle(translate('multipleCopyOfTask'));
		asideBuilder.setComponent(MultipleCopyForm);
		asideBuilder.setComponentKey('multipleCopy-' + item.id);
		asideBuilder.setAutoClose(false);
		asideBuilder.setAutoClear(false);
		asideBuilder.setCloseOnOutsideClick(true);
		asideBuilder.setComponentProps({
			data: item,
			service: service,
		});
		asideBuilder.setSaveCallback(performMultipleCopy);

		asideBuilder.setOnClose(() => asideBuilder.reset());

		asideBuilder.build();
	};

	const performMultipleCopy = async (data) => {
		const clonedData = { ...data };
		if (data) {
			try {
				await service.createMultipleCopy(data.id, data).then((response) => {
					if (response.succeeded === false) {
						if (response.message === 'DuplicatedEntity') {
							Swal.fire({
								title: translate('duplicatedEntity'),
								text: translate('doYouWantToCreateAnyWay'),
								icon: 'warning',
								showCancelButton: true,
								confirmButtonText: translate('createAnyWay'),
								confirmButtonColor: 'rgb(239, 68, 68)',
								cancelButtonText: translate('cancel'),
								cancelButtonColor: '#b5bec9',
								showLoaderOnConfirm: true,
								preConfirm: async () => {
									await service
										.createMultipleCopy(clonedData.id, clonedData, true)
										.then((response) => {
											if (response.succeeded === false) {
												Swal.showValidationMessage(
													translate(response.message) || translate('somethingWentWrong'),
												);
												return;
											} else {
												toast(translate('tasksAreCreatedSuccessfully'), {
													duration: 2000,
													icon: '✅',
												});
												asideBuilder.setOpen(false);
												asideBuilder.reset();
											}
										});
									fetchAndLoad();
								},
							});
						} else {
							toast(translate(response.message) || translate('somethingWentWrong'), {
								duration: 2000,
								icon: '❗️',
							});
						}

						Swal.showValidationMessage(translate(response.message) || translate('somethingWentWrong'));
						return;
					} else {
						toast(translate('tasksAreCreatedSuccessfully'), {
							duration: 2000,
							icon: '✅',
						});
						asideBuilder.setOpen(false);
						asideBuilder.reset();
					}
				});
				fetchAndLoad();
			} catch (error) {
				console.error('Error on action', error);
				Swal.hideLoading();
				Swal.showValidationMessage(translate(error.message) || translate('somethingWentWrong'));
				throw error;
			}
		}
	};

	const ReturnBackHandler = () => {
		cloneTask({ ...item, getServiceMethodName: 'getReturn' });
	};

	const NextLocationHandler = (toTask) => {
		editTask({ ...item, secondParamId: item.id, id: toTask.id, getServiceMethodName: 'getNextLocation' });
	};

	//must get the list of actions based on the status
	const actions = taskActions.filter((action) => action.statuses.includes(status));

	const actionButtons = buttons.filter(
		(button) =>
			(button.id === -1 && hasViewPermission) || //Check if the user has view permission to show the view button
			actions.map((action) => action.action.id).includes(button.id) ||
			(button.children &&
				button.children.some((child) => actions.map((action) => action.action.id).includes(child.id))),
	);

	useEffect(() => {
		modalsVisible(allocateDialogVisible || returnDialogVisible);
	}, [allocateDialogVisible, returnDialogVisible]);

	return (
		<>
			<div className='flex flex-row justify-start -mt-1 pb-2 px-5'>
				<div className='flex flex-row justify-center items-center'>
					<div className='flex flex-row justify-center items-center space-x-5 py-4'>
						{actionButtons.map((action, index) => (
							<ActionButton key={index} {...action} item={item} onClick={() => action.onClick(item)} />
						))}
					</div>
				</div>
			</div>
			<AllocateHandler
				fetchAndLoad={fetchAndLoad}
				service={service}
				items={[item]}
				isVisible={allocateDialogVisible}
				onClose={() => setAllocateDialogVisible(false)}
			/>
			<ReturnTask
				fetchAndLoad={fetchAndLoad}
				service={service}
				item={item}
				isVisible={returnDialogVisible}
				onClose={() => setReturnDialogVisible(false)}
				onReturnBack={() => ReturnBackHandler()}
				onToTaskClick={(taskTo) => NextLocationHandler(taskTo)}
			/>
		</>
	);
};

export default ActionRow;
