import Tooltip from 'components/ui/Input/internal/Tooltip';
import { useOutsideClickHandler, useTranslations } from 'hooks';
import useDebounce from 'hooks/useDebounce';
import { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { buildFilterParams, buildPagination } from 'utils/pagination';
import ViewInput from './internal/ViewInput';

const Options = memo(({ option, handleSelectOption, selectedValue, isFocused = false, icon = null }) => {
	const { translate } = useTranslations();
	const optionLabel = translate(option?.label || '', true);
	return (
		<li
			className={`
                px-4 py-2 cursor-pointer hover:bg-gray-200 border-b border-gray-100 text-sm ${
					option.value === selectedValue ? 'bg-gray-100' : ''
				}
                ${isFocused ? 'bg-gray-200' : ''}
                `}
			onClick={() => {
				handleSelectOption(option.value, optionLabel, icon);
			}}
		>
			{icon && <i className={`${icon} mr-2 text-xl text-gray-500`}></i>}
			{optionLabel}
		</li>
	);
});

const ReSelect = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();

	const inputRef = useRef(null);
	const searchInputRef = useRef(null);
	const endOfDivRef = useRef(null);
	const endReachedRef = useRef(false);
	const observer = useRef();
	const lastOptionElementRef = useRef();
	const hasMounted = useRef(false);

	const metaRef = useRef({ page: 1, size: 10, sortBy: 'id', sortAsc: true });
	const toggleRef = useOutsideClickHandler(() => isOpen && setIsOpen(false));
	const [inputInvalid, setInputInvalid] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const searchFilters = useDebounce(searchTerm, props?.service ? 500 : 0);

	const [selectedValue, setSelectedValue] = useState(null);
	const [selectedLabel, setSelectedLabel] = useState('');
	const [selectedIcon, setSelectedIcon] = useState(null);

	const [options, setOptions] = useState(props?.options ?? []);

	const [filteredOptions, setFilteredOptions] = useState(props?.options ?? []);

	const [isLoading, setIsLoading] = useState(false);
	const [focusedIndex, setFocusedIndex] = useState(-1);

	const checkValidity = () => {
		setInputInvalid(inputRef.current && inputRef.current.validity.valid === false);
	};

	useEffect(() => {
		checkValidity();
	}, [selectedValue]);

	// Filter options based on search term
	useEffect(() => {
		if (searchTerm) {
			setFilteredOptions(
				options?.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase())),
			);
		} else {
			setFilteredOptions(options);
		}
	}, [searchTerm, options]);

	useEffect(() => {
		if (props.options) {
			setOptions(props.options);
			setFilteredOptions(props.options);
		}
	}, [props.options]);

	useEffect(() => {
		if (isOpen && props.service) {
			fetchOptions();
		}
	}, [isOpen]);

	// Handle option selection
	const handleSelectOption = (value, label, icon = null) => {
		setSelectedValue(value);
		setSelectedLabel(label);
		setSelectedIcon(icon);
		setSearchTerm('');
		setIsOpen(false);
		props.onSelect(value);
		if (props?.onChangeObject === true) {
			props.onChange({ Id: value, Name: label });
		} else {
			props.onChange(value);
		}
	};

	const resetSelectedValue = () => {
		props.onSelect(null);
		props.onChange(null);
		setSelectedValue(null);
		setSelectedLabel('');
		setSelectedIcon(null);
		setFocusedIndex(-1);
	};

	useEffect(() => {
		const value = props?.value || props?.defaultValue;
		if (!value) return;

		if (value && typeof value === 'object') {
			setSelectedValue(value.Id || value.id);
			setSelectedLabel(value.Name || value.name);
			if (value.icon) {
				setSelectedIcon(value.icon);
			}
		} else {
			setSelectedValue(value);

			if (props.service && value && value > 0) {
				if (selectedLabel !== '') {
					return;
				}
				getLabel(value).then((label) => {
					setSelectedLabel(label);
				});
			} else {
				// Find the label for the selected value
				const selectedOption = props?.options?.find((option) => option.value === value);
				if (selectedOption) {
					setSelectedLabel(selectedOption.label);
					if (selectedOption?.icon) {
						setSelectedIcon(selectedOption.icon);
					}
				} else {
					setSelectedLabel('');
					setSelectedIcon(null);
				}
			}
		}
	}, [props?.value, props?.defaultValue, props?.options]);

	useEffect(() => {
		if (!hasMounted.current && props?.defaultSelected) {
			hasMounted.current = true;
			const value = props?.defaultSelected;

			if (value && typeof value === 'object') {
				setSelectedValue(value.Id || value.id);
				setSelectedLabel(value.Name || value.name);
				if (value.icon) {
					setSelectedIcon(value.icon);
				}
			} else {
				setSelectedValue(value);

				if (props.service && value && value > 0) {
					if (selectedLabel !== '') {
						return;
					}
					getLabel(value).then((label) => {
						setSelectedLabel(label);
					});
				} else {
					// Find the label for the selected value
					const selectedOption = props?.options?.find((option) => option.value === value);
					if (selectedOption) {
						setSelectedLabel(selectedOption.label);
						if (selectedOption?.icon) {
							setSelectedIcon(selectedOption.icon);
						}
					} else {
						setSelectedLabel('');
						setSelectedIcon(null);
					}
				}
			}
		}
	}, [props?.value, props?.defaultSelected, props?.options]);

	useEffect(() => {
		if (props.service && isOpen) {
			endReachedRef.current = false;
			metaRef.current = {
				...metaRef.current,
				page: 1,
			};
			const queryString = buildQueryString(searchFilters);

			if (searchFilters === '') {
				fetchOptions();
			} else if (searchFilters.length >= 3) {
				fetchOptions(queryString);
			}
		}
	}, [searchFilters]);

	useEffect(() => {
		if (isOpen) {
			searchInputRef.current.focus();
			endOfDivRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			});
		}
	}, [isOpen]);

	useEffect(() => {
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(async (entries) => {
			if (entries[0].isIntersecting) {
				if (props?.service && !endReachedRef.current && !isLoading && filteredOptions?.length > 0) {
					// Load more options when the last option comes into view
					metaRef.current = {
						...metaRef.current,
						page: metaRef.current?.page + 1,
					};

					const queryString = buildQueryString(searchTerm);
					await fetchOptions(queryString, true);
				}
			}
		});

		if (lastOptionElementRef.current) {
			observer.current.observe(lastOptionElementRef.current);
		}
		if (!isOpen) {
			endReachedRef.current = false;
			metaRef.current = {
				...metaRef.current,
				page: 1,
			};
		}
	}, [isOpen, filteredOptions]); // Re-run the effect when the filteredOptions array changes

	const buildQueryString = (query) => {
		const pgn = { ...metaRef.current, sortBy: 'id', sortAsc: true };
		const params = { prefix: query };

		const paginationStr = buildPagination(pgn);
		const filterStr = buildFilterParams(params);
		return `${paginationStr}&${filterStr}`;
	};

	// Handle keydown event for list navigation
	const handleKeyDown = (event) => {
		switch (event.key) {
			case 'ArrowDown':
				event.preventDefault();
				setFocusedIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions?.length - 1));
				break;
			case 'ArrowUp':
				event.preventDefault();
				setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
				break;
			case 'Enter':
				event.preventDefault();
				if (focusedIndex >= 0 && focusedIndex < filteredOptions?.length) {
					const focusedOption = filteredOptions?.[focusedIndex];
					handleSelectOption(focusedOption.value, focusedOption.label);
				}
				break;
			default:
				break;
		}
	};

	const fetchOptions = async (queryString = '', append = false, callback = () => {}) => {
		if (props.service) {
			if (queryString === '') {
				queryString = 'page=1&PageSize=10&sortBy=id&sortAsc=true';
			}
			if (props?.extraParams) {
				queryString += `&${props.extraParams}`;
			}
			setIsLoading(true);
			props.service.getOptionsList(queryString).then((res) => {
				if (res?.meta) {
					metaRef.current = res.meta;
				}
				if (res?.data?.length < metaRef.current.size) {
					endReachedRef.current = true;
				}

				let newOptions = res.data;
				if (append && res.data) {
					newOptions = [...options, ...res.data];
				}
				setOptions(newOptions);
				setFilteredOptions(newOptions);
				setIsLoading(false);
				callback(res.data);
			});
		}
	};

	const getLabel = async (id, labelKey = 'label') => {
		if (props.service) {
			const res = await props.service.getOptionsList(`id=${id}`);
			return res?.data?.[0]?.[labelKey] || '';
		}
		return id;
	};

	if (isView) {
		return <ViewInput value={selectedLabel} />;
	}

	return (
		<Tooltip text={translate(props.title ?? null, true)}>
			<div
				ref={toggleRef}
				className={`z-10 w-full ${props.containerClassName} ${
					props.disabled ? 'opacity-50 pointer-events-none cursor-not-allowed' : 'opacity-100'
				}`}
			>
				{!props.hideLabel && props.label && (
					<label className={`block mb-1 text-xs font-medium text-gray-700 ${props.labelClassName}`}>
						{translate(props.label, true)}
					</label>
				)}
				<div className='z-10 flex flex-row items-center'>
					<div className={`relative inline-block w-full`} onKeyDown={handleKeyDown}>
						{/* Hidden input to store selected value */}
						<select
							ref={inputRef}
							type='text'
							className='sr-only pt-10 pl-10'
							required={props?.required ?? false}
							name={props.name}
							onChange={() => {}}
						>
							<option disabled selected></option>
							{selectedValue && (
								<option value={selectedValue} selected>
									{selectedLabel}
								</option>
							)}
						</select>
						<button
							type='button'
							className={`inline-flex z-10 items-center w-full bg-gray-50 border border-gray-300 ${
								inputInvalid ? 'border-red-500' : ' border-gray-300'
							} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  ${
								props?.small === true ? 'py-1' : 'py-2.5'
							} px-2.5 my-1 ${props.className}`}
							onClick={() => setIsOpen(!isOpen)}
						>
							{selectedLabel ? (
								<>
									<div className='flex flex-1 w-full items-center'>
										{selectedIcon && (
											<i className={`${selectedIcon} mr-2 text-sm text-gray-500`}></i>
										)}
										{translate(selectedLabel, true)}
									</div>
									<div
										onClick={(e) => {
											e.stopPropagation();
											resetSelectedValue();
										}}
									>
										<i className='ri-close-circle-fill opacity-50 hover:opacity-100 -mr-1 ml-2 h-5 w-5'></i>
									</div>
								</>
							) : (
								<div className='flex flex-1 w-full items-start text-slate-400'>
									{props.placeholder ? translate(props?.placeholder) : translate('select')}
								</div>
							)}
							<i className='ri-arrow-down-s-line -mr-1 ml-2 h-5 w-5'></i>
						</button>

						{isOpen && (
							<div
								className={`${
									props?.listClass ? props.listClass : 'absolute'
								}   top-full z-20 -mt-3 w-full bg-white border-b border-x border-gray-300 rounded-md shadow-lg`}
								ref={endOfDivRef}
							>
								<input
									ref={searchInputRef}
									type='text'
									className='w-full p-1 px-3 border-b border-gray-300 bg-gray-100 focus:outline-none shadow-inner rounded-lg '
									placeholder={translate('search') + '...'}
									defaultValue={searchTerm}
									onChange={(e) => setSearchTerm(e.target.value)}
								/>
								{isLoading && (
									<div className='p-2 text-center'>
										<div className='flex justify-center items-center h-8'>
											<i className='ri-loader-4-line animate-spin text-gray-500 text-xl'></i>
										</div>
									</div>
								)}
								<ul className='max-h-60 overflow-y-auto' onWheel={(e) => e.stopPropagation()}>
									{filteredOptions?.map((option, index) => (
										<Options
											icon={option?.icon}
											key={option.value}
											option={option}
											handleSelectOption={handleSelectOption}
											selectedValue={selectedValue}
											isFocused={index === focusedIndex}
										/>
									))}
									<li className='px-4 -my-1 opacity-0' ref={lastOptionElementRef}>
										-
									</li>
									{!isLoading && filteredOptions?.length === 0 && (
										<li className='px-4 py-2 pb-4 text-sm text-center'>{translate('noResults')}</li>
									)}
								</ul>
							</div>
						)}
					</div>
				</div>
			</div>
		</Tooltip>
	);
});

ReSelect.defaultProps = {
	options: [],
	defaultValue: null,
	required: false,
	className: '',
	service: null,
	name: '',
	value: null,
	onSelect: () => {},
	onChange: () => {},
	hideLabel: false,
	containerClassName: '',
	label: '',
	disabled: false,
};

export default ReSelect;
