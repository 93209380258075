import { Navigate } from 'react-router-dom';

import Dashboard from '../pages/Dashboard';
import Manage from '../pages/Manage';
import Planning from '../pages/Planning';
import Rules from '../pages/Rules';
import Settings from '../pages/Settings';
import Tasks from '../pages/Tasks';

const routes = [
	{ index: true, element: <Navigate to='tasks' replace /> },

	{ path: 'tasks/*', element: <Tasks /> },
	{ path: 'dashboard/*', element: <Dashboard /> },
	{ path: 'planning/*', element: <Planning /> },
	{ path: 'rules/*', element: <Rules /> },
	{ path: 'manage/*', element: <Manage /> },
	{ path: 'settings/*', element: <Settings /> },

	{ path: '*', element: <Navigate to={''} replace /> },
	// { index: true, element: <Navigate to={"/media"} replace /> },
];

export default routes;
